import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const App = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      backgroundColor="black"
    >
      <Text
        fontSize="6xl"
        color="pink"
        fontWeight="bold"
        textShadow="0 0 10px #FF00FF, 0 0 20px #FF00FF, 0 0 30px #FF00FF, 0 0 40px #FF00FF, 0 0 50px #FF00FF, 0 0 60px #FF00FF, 0 0 70px #FF00FF"
      >
        Play. EARN. OWN.
      </Text>
    </Box>
  );
};

export default App;
